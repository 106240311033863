//Thumbnail Slider on single yacht pages

document.addEventListener( 'DOMContentLoaded', function () {
  const mainSlider = document.querySelector( '#main-slider' ) ? new Splide( '#main-slider', {
    type      : 'fade',
    rewind    : true,
    pagination: false,
    arrows    : false,
  } ) : null
  const thumbnailSlider = document.querySelector( '#thumbnail-slider' ) ? new Splide( '#thumbnail-slider', {
    fixedWidth  : 160,
    fixedHeight : 115,
    gap         : 10,
    rewind      : true,
    pagination  : false,
    cover       : true,
    isNavigation: true,
    breakpoints : {
      600: {
        fixedWidth : 60,
        fixedHeight: 44,
      },
    },
  } ) : null

  if (mainSlider && thumbnailSlider) {
    mainSlider.sync(thumbnailSlider);
  }
  if (mainSlider) {
    mainSlider.mount();
  }
  if (thumbnailSlider) {
    thumbnailSlider.mount();
  }

  const salesForms = document.querySelectorAll("form[action=\"/yacht-sales/\"]")
  for (let i = salesForms.length - 1; 0 <= i; --i) {
    const select = salesForms[i].querySelector('select')
    if (!select) {
      continue
    }

    select.addEventListener('change', event => {
      /** @var {HtmlElement} formNode */
      let formNode = event.target
      while (formNode && "FORM" !== formNode.tagName.toUpperCase()) {
        formNode = formNode.parentNode
      }

      if (!formNode) {
        return
      }

      formNode.submit()
    })
  }
  
} );